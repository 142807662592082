<template>
    <div>
      <!--   <footer>
      <p class="tips1">
        中国政府网&emsp;|&emsp;国务院国有资产监督管理委员会&emsp;|&emsp;中国化学工程集团有限公司&emsp;|&emsp;中国化学工程股份有限公司&emsp;|&emsp;更多链接
      </p>
      <p class="tips2">京ICP备17025820号&emsp;中国化学工程集团有限公司&emsp;版权所有</p>
      <p class="tips3">
        电话：+86&emsp;01059765575&emsp;|&emsp;E-mail：cncec@cncec.com.cn&emsp;办公地址：中国北京东城区东直门内大街2号
      </p>
    </footer> -->
    </div>
</template>
<script>
export default {
    data() {
        return {}
},
    mounted() {},
    methods: {},
    computed: {},
    watch: {},
}
</script>
<style lang="scss" scoped>
 footer {
    background-color: rgb(2, 112, 193);
    height: 100px;
    text-align: center;
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    p {
      text-align: center;
      font-size: xx-small;
    }
    .tips1 {
      line-height: 50px;
    }
    .tips2 {
      line-height: 30px;
    }
  }
</style>